import { useRef, useState, useEffect } from "react";
import { ZIPCODES } from "../../../Constants/Constant";
import Image1 from "../../../../Assets/images/homefsa.jpg";
import Form from "./form";
import { Link, useNavigate } from "react-router-dom";
import { SiMinutemailer } from "react-icons/si";
import { FiMail } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import { MdSubject } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMessage } from "react-icons/ai";
import { FaCity } from "react-icons/fa";
import { FaTreeCity } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "emailjs-com";

import { useTranslation } from 'react-i18next';

const Hero = () => {

  const { t } = useTranslation();

  const [text, setText] = useState("");
  const phrases = t('generalComponents.phrases', { returnObjects: true });
  const [city, setCity] = useState("");

  const [hideInitialForm, setHideInitialForm] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [showSquareMeterInput, setShowSquareMeterInput] = useState(false);
  const [squareMeter, setSquareMeter] = useState("");

  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);
  const zipcodeRef = useRef();
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [surName, setSurName] = useState("");
  const [telephone, setTelephone] = useState("");
/*   const [city, setCity] = useState("");
 */  const [company, setCompany] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const isValidEmail = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !fullName ||
      !email ||
      !surName ||
      !city ||
      !company ||
      !message

    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }


    try {
      const messageBody = `
          Request A Free Quote!:
           
               Name: ${fullName} 
               SurName;${surName}
               Email: ${email}
               Telephone:${telephone}
               City:${city}
               Company:${company}
               Message: ${message}
              
           `;
      const templateParams = {
        to_name: `${fullName}`,
        from_name: "HMFLYTT Team",
        to_email: email,
        subject: "Request A Free Quote!",
        message: messageBody,
      };
      const response = await emailjs.send(
        "service_tkepj1s",
        "template_2qj2i3r",
        templateParams,
        "H-hG0vqXi4PYjR7Ld"
      );
      console.log("Email sent successfully:", response);
      if (response) {
        toast.success("Email Sent Successfully");

        setEmail("");

        setFullName("");
        setMessage("");
        setSubject("");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Something Went wrong please try again", error);
    }
  };

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    // Introduce a delay (you can adjust the duration)
    const delayDuration = 500; // in milliseconds
    setTimeout(() => {
      // Now, navigate to the desired route
    }, delayDuration);
  };
  let currentIndex = 0;
  const colorMapping = {
    " Home": "green",
    " Deep": "indigo",
    " Window": "green",
    "Moving Out": "purple",
    "Home Renovation": "blue",
    "Handyman Help": "orange",
    "Moving Services": "gray",
    "Office": "red",
    "Office Relocation": "yellow",
    "Recycling Services": "purple",
  };
  useEffect(() => {
    const typeText = () => {
      if (currentIndex < phrases.length) {
        setText(phrases[currentIndex]);
        currentIndex++;
      } else {
        currentIndex = 0;
        setText(phrases[currentIndex]);
        currentIndex++;
      }
    };

    const interval = setInterval(typeText, 3000);

    return () => clearInterval(interval);
  }, []);
  const handleRef = () => {
    zipcodeRef.current.focus();
  };
  const handleCitySelection = () => {
    setHideInitialForm(true);
  };
  const handleServiceSelect = (selectedService) => {
    setSelectedService(selectedService);
    setShowSquareMeterInput(true);
  };
  const handleInputChange = (e) => {
    const filteredCity = ZIPCODES.filter(
      (item) => item?.zipcode == e.target.value
    );
    if (filteredCity.length > 0) {
      setCode(e.target.value);
      setCity(filteredCity[0]?.city);
      setShow(true);
    } else {
      setCode(e.target.value);
      setShow(false);
    }
  };
  const textColor = colorMapping[text] || "black";
  const isCleaningHidden = ["Moving Services", "Office Relocation", "Recycling Services", "Home Renovation", "Handyman Help", "Hem renovering", "Handyman Help", "Flytt tjänster", "Kontors flytt", "Återvinnings tjänster"].includes(text);

  const isButtonDisabled = !city || !code || code.length > 5 || code.length <= 4;

  /*  const isButtonDisabled = !city || !code || (code.length > 5 || code.length <= 4);
   */
  /*   const isButtonDisabled = !city || !code || code.length !== 5;
   */
  /*  const navigate = useNavigate();
   const handleNavigate = () => {
     // Add your logic here for handling the navigation
     navigate("/Recycling", {
       state: {
         data: {
           city,
         },
       },
     });
   }; */
  const handleSquareMeterInputChange = (e) => {
    setSquareMeter(e.target.value);
  };
  const handleNavigate = () => {

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    let route = "/Booking"; // Default route for Home Cleaning

    switch (selectedService) {
      case "Home Cleaning":
        route = "/Booking";
        break;
      case "Window Cleaning":
        route = "/window_cleaning";
        break;
      case "Deep Cleaning":
        route = "/deep_cleaning";
        break;
      case "Dödsbo":
        route = "/Dödsbo_Form";
        break;
      case "Move Out Cleaning":
        route = "/moving_out_cleaning";
        break;
      case "Moving And MoveOut Cleaning":
        route = "/moving_out_service2";
        break;
      case "Moving Service":
        route = "/moving_out_service1";
        break;
      case "Home Renovation":
        route = "/home_Renovation1";
        break;
      case "General  Handyman":
        route = "/Generalhandyman1";
        break;
      case "Office Cleaning":
        route = "/office_cleaning_contact";
        break;
      case "For Housing Cooperation":
        route = "/for_housing_cooperative_contact";
        break;
      case "Office Relocation":
        route = "/office_relocation_contact";
        break;
      case "Cleaning As Salery Benifit":
        route = "/cleaning_as_salery_benifit_contact";
        break;

      default:
        // Default to /Booking if the selected service is not recognized
        route = "/Booking";
    }

    navigate(route, {
      state: {
        data: {
          city,
        },
      },
    });
  };

  return (
    <div
      className="bg-cover flex flex-col md:flex-row"
      style={{
        backgroundImage:
          `url(${Image1})`,
      }}
    >

      <div className="mr-auto w-full md:w-3/6 px-10 text-5xl font-medium font-['Young Serif'] ">
        <div className="hover:text-gray-600 ">
          <div className="text-start mr-auto pt-40 md:px-10 px-10  text-5xl font-medium font-['Young Serif'] ">

            {isCleaningHidden ? (
              <>
                <span
                  className="text-left font-bold"
                  style={{ color: textColor }}
                >
                  {text.split(' ')[0]}
                </span>
                <br />
                <span
                  className="text-left font-bold"
                  style={{ color: 'textColor' }}
                >
                  {text.split(' ')[1]}
                </span>
              </>
            ) : (
              <span
                className="text-left font-bold"
                style={{ color: textColor }}
              >
                {text}
              </span>
            )}
            <div className="text-red-600 ">
              {!isCleaningHidden && `${t('generalComponents.cleaning')}`}
            </div>

          </div>

          <div>{!hideInitialForm && (
            <>
              {" "}
              <div className="flex w-full md:w-[500px]  hover:shadow-black text-lg font-bold hover:border-none hover:text-green-500 transform hover:scale-90 transition-transform delay-200">
                {code.length > 0 ? (
                  <input
                  className="md:flex-1 w-[200px] border-2 border-r-0 border-gray-600 md:border-0 bg-white md:focus:border-none md:focus:outline-none text-black text-left md:text-right text-lg p-3 rounded-l-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500"
                  type="number"
                  value={code}
                  maxLength="5"
                  ref={zipcodeRef}
                  onChange={handleInputChange}
                />
                ):(
                  <input
                  className="md:flex-1 w-[500px] border-2 border-r-0 border-gray-600 md:border-0 bg-white md:focus:border-none md:focus:outline-none text-black text-left md:text-right text-xs md:text-xs py-3 px-1 md:px-3 rounded-l-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500"
                  type="number"
                  placeholder={`${t('generalComponents.hero.placeHolderZipcodeHome')}`}
                  value={code}
                  maxLength="5"
                  ref={zipcodeRef}
                  onChange={handleInputChange}
                />
                )}

                <div
                  onClick={handleRef}
                  className="md:flex-1 flex items-center justify-between border-2 border-l-0 border-gray-600 md:border-0 bg-white focus:border-none focus:outline-none text-black text-lg p-3 rounded-r-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500"
                >
                  {show && city && (
                    <>
                      <p className="text-left">{city} </p>
                      <p>
                        <svg
                          height="24"
                          width="24"
                          viewBox="0 0 24 24"
                          class="fcNb"
                          style={{ fill: "green" }}
                        >
                          <g>
                            <path d="m12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm0 22.286a10.286 10.286 0 1 1 0-20.572 10.286 10.286 0 0 1 0 20.572z"></path>
                            <path d="m17.4 7.114-6.257 6.24-2.828-2.81a.848.848 0 1 0 -1.2 1.2l3.428 3.428a.824.824 0 0 0 1.2 0l6.857-6.858a.849.849 0 0 0 -1.2-1.2z"></path>
                          </g>
                        </svg>
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div
                className="w-2/5 border-0"
                style={{ marginTop: "-10px" }}
              >
                {!show && (code.length >= 1 || code.length > 5) && (
                  <p className="text-red-500 text-xs text-center mt-2 flex items-center justify-center">
                    {t('generalComponents.dropDown.error1')}
                  </p>
                )}

                {!show && code.length === 5 && (
                  <p className="text-red-500 text-xs text-center mt-2">
                    {t('generalComponents.dropDown.error2')}
                  </p>
                )}
              </div>
              <div
                className="flex  w-4/5 md:w-[500px] justify-center items-center  mt-4 p-5 hover:shadow-lg hover:shadow-black bg-[#1e1e1e] text-lg font-bold  text-white rounded-md hover:text-green-500 transform hover:scale-90 transition-transform delay-200">
                <button
                  className={`flex justify-center items-center  hover:shadow-lg hover:shadow-black bg-[#1e1e1e] text-lg font-bold  text-white rounded-md ${isButtonDisabled || (!show && code.length === 5)
                    ? "cursor-not-allowed opacity-50"
                    : "hover:text-green-500 transform hover:scale-90 transition-transform delay-200"
                    }`} onClick={handleCitySelection}
                  disabled={isButtonDisabled}
                >
                  {t('generalComponents.hero.chooseServices')}
                </button>
                <p className="text-center text-4xl  self-end absolute end-0 mr-4 ">
                  <svg
                    height="20"
                    viewBox="0 0 9 15"
                    width="20"
                    class="csw9N_C5 fiwAtSA"
                    style={{ stroke: "#fff" }}
                  >
                    <path
                      d="m.958.995 6.51 6.51-6 6"
                      fill="none"
                      stroke-width="2"
                    ></path>
                  </svg>
                </p>
              </div>
            </>
          )}
            {hideInitialForm && (
              <div>
                <select
                  value={selectedService}
                  onChange={(e) => handleServiceSelect(e.target.value)}
                  className="flex w-1/2 md:w-[500px] mt-6  hover:shadow-black text-lg font-bold hover:border-none hover:text-green-500 transform hover:scale-90 transition-transform delay-200">

                  <option value="" disabled className='text-center'> {t('generalComponents.dropDown.dropDownElement1')}</option>
                  <option value="Moving Service" className='text-center'> {t('generalComponents.dropDown.dropDownElement6')}</option>
                  <option value="Move Out Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement5')}</option>
                  <option value="Moving And MoveOut Cleaning" className='text-center'> {t('contactPages.movingAndMoveOutCleaning')}</option>
                  <option value="Office Relocation" className='text-center'> {t('generalComponents.dropDown.dropDownElement11')}</option>
                  <option value="Office Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement9')}</option>
                  <option value="Home Renovation" className='text-center'> {t('generalComponents.dropDown.dropDownElement7')}</option>
                  <option value="General  Handyman" className='text-center'> {t('generalComponents.dropDown.dropDownElement8')}</option>
                  <option value="Home Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement2')}</option>
                  <option value="Window Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement3')}</option>
                  <option value="Deep Cleaning" className='text-center'> {t('generalComponents.dropDown.dropDownElement4')}</option>
                  <option value="Dödsbo" className='text-center'> Dödsbo</option>
                  <option value="For Housing Cooperation" className='text-center'> {t('generalComponents.dropDown.dropDownElement10')}</option>
                  <option value="Cleaning As Salery Benifit" className='text-center'> {t('generalComponents.dropDown.dropDownElement12')}</option>
                </select>
                {/* {showSquareMeterInput && (
                                    <div className="flex ">
                                        <input
                                            type="number"
                                            value={squareMeter}
                                            onChange={handleSquareMeterInputChange}
                                            className="flex-1 bg-white focus:border-none focus:outline-none text-black text-right text-lg p-3 rounded-l-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500"
                                            placeholder="Enter Square Meter"
                                        />

                                        <div className="flex-1 flex items-center justify-between bg-white focus:border-none focus:outline-none text-black text-lg p-3 rounded-r-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500">
                                            {squareMeter && (
                                                <>
                                                    <p className="text-left"> sqm </p>
                                                    <p className="text-right">
                                                        <svg
                                                            height="24"
                                                            width="24"
                                                            viewBox="0 0 24 24"
                                                            class="fcNb"
                                                            style={{ fill: "green" }}
                                                        >
                                                            <g>
                                                                <path d="m12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm0 22.286a10.286 10.286 0 1 1 0-20.572 10.286 10.286 0 0 1 0 20.572z"></path>
                                                                <path d="m17.4 7.114-6.257 6.24-2.828-2.81a.848.848 0 1 0 -1.2 1.2l3.428 3.428a.824.824 0 0 0 1.2 0l6.857-6.858a.849.849 0 0 0 -1.2-1.2z"></path>
                                                            </g>
                                                        </svg>
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )} */}
                <div className="flex w-4/5 md:w-[450px] justify-center items-center  bg-opacity-80 hover:shadow-lg hover:shadow-black bg-[#1e1e1e] text-lg font-bold mt-4 p-5 rounded-md hover:text-green-500 transform hover:scale-90 transition-transform delay-200">
                  <button
                    className={`text-center text-['#fff']`}
                    onClick={handleNavigate}
                    disabled={!selectedService}
                  >
                    {t('generalComponents.hero.continueButton')}
                  </button>
                  <p className="text-center text-4xl  self-end absolute end-0 mr-4 ">
                    <svg
                      height="20"
                      viewBox="0 0 9 15"
                      width="20"
                      class="csw9N_C5 fiwAtSA"
                      style={{ stroke: "#fff" }}
                    >
                      <path
                        d="m.958.995 6.51 6.51-6 6"
                        fill="none"
                        stroke-width="2"
                      ></path>
                    </svg>
                  </p>
                </div>
              </div>
            )}</div>
          {/* <div className="flex w-1/2 md:w-[400px]  hover:shadow-black text-lg font-bold hover:border-none hover:text-green-500 transform hover:scale-90 transition-transform delay-200">
            <input
              className="flex-1 bg-white focus:border-none focus:outline-none text-black text-right text-lg p-3 rounded-l-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500"
              type="number"
              placeholder="ENTER ZIPCODE"
              value={code}
              ref={zipcodeRef}
              onChange={handleInputChange}
            />

            <div
              onClick={handleRef}
              className="flex-1 flex items-center justify-between bg-white focus:border-none focus:outline-none text-black text-lg p-3 rounded-r-lg mt-10 placeholder-black font-bold cursor-pointer bg-opacity-80 transform transition-transform delay-200 hover:placeholder-gray-500"
            >
              {show && city && (
                <>
                  <p className="text-left">{city} </p>
                  <p>
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      class="fcNb"
                      style={{ fill: "green" }}
                    >
                      <g>
                        <path d="m12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm0 22.286a10.286 10.286 0 1 1 0-20.572 10.286 10.286 0 0 1 0 20.572z"></path>
                        <path d="m17.4 7.114-6.257 6.24-2.828-2.81a.848.848 0 1 0 -1.2 1.2l3.428 3.428a.824.824 0 0 0 1.2 0l6.857-6.858a.849.849 0 0 0 -1.2-1.2z"></path>
                      </g>
                    </svg>
                  </p>
                </>
              )}
            </div>
          </div>
          <div
            className="w-2/5 border-0"
            style={{ marginTop: "-10px" }}
          >
           
            {!show && (code.length >= 1 || code.length > 5) && (
              <p className="text-red-500 text-xs text-center mt-2 flex items-center justify-center">
                This is not a valid zip code.
              </p>
            )}

            {!show && code.length === 5 && (
              <p className="text-red-500 text-xs text-center mt-2">
                Unfortunately, none of our services are available in the
                area.
              </p>
            )}
          </div>
          <button
            disabled={isButtonDisabled}
            onClick={handleNavigate}
            
            className={`flex w-[400px] md:w-[400px] bg-opacity-80 hover:shadow-lg hover:shadow-black bg-[#1e1e1e] text-lg font-bold mt-4 p-5 rounded-md ${isButtonDisabled || (!show && code.length === 5)
              ? "cursor-not-allowed opacity-50"
              : "hover:text-green-500 transform hover:scale-90 transition-transform delay-200"
              }`}>
            <p className="ml-24 text-['#fff']">CONTINUE </p>
          </button> */}
          <div className="text-xl mt-10 flex items-start font-normal">
            <div className="mt-5">

            </div>
          </div>
        </div>

      </div>
      <div className='mt-24 mb-5 mr-4 w-[100%] md:w-3/6'>
        <form
          onSubmit={handleSubmit} 
          className="flex bg-transparent flex-col  transition duration-300 rounded-xl mt-2 px-4 border-t-4 border-yellow-500 rounded-tl-2xl rounded-tr-2xl shadow-md"
        >
          <h2 className=" text-black text-xl font-bold mt-2 text-center ">
            {t('home.hero.form.heading')}
          </h2>
          <p className='text-yellow-500 mt-2'>{t('home.hero.form.paragraphFillInTheForm')} </p>
          <div className="flex flex-col gap-6 gap-y-5">

            <div className="flex flex-col sm:flex-row relative items-center gap-2">
              <div className="flex relative items-center w-full">
                <BiUser className="absolute left-1 w-9 h-9 p-1 text-gray-500 hover:shake" />
                <input
                  className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                  type="text"
                  placeholder={`${t('home.hero.form.placeHolderFirstName')}`}
                  name="fullName"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  id="fullName"
                />
              </div>
              <div className="flex relative items-center w-full">
                <BiUser className="absolute left-1 w-9 h-9 p-1 text-gray-500 hover:shake" />
                <input
                  className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                  type="text"
                  placeholder={`${t('home.hero.form.placeHolderLastName')}`}
                  name="surName"
                  value={surName}
                  onChange={(e) => setSurName(e.target.value)}
                  id="surName"
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row relative items-center gap-2">
              <div className="flex relative items-center w-full">
                <FiMail className=" absolute left-1 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                <input
                  className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                  placeholder={`${t('home.hero.form.placeHolderEmailAddress')}`}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="email"
                  id="email"
                  name="email"
                />
              </div>
              <div className="flex relative items-center w-full">
                <BsTelephone className=" absolute left-1 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                <input
                  className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                  placeholder={`${t('home.hero.form.placeHolderTelephone')}`}
                  onChange={(e) => setTelephone(e.target.value)}
                  value={telephone}
                  type="telephone"
                  id="telephone"
                  name="telephone"
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row relative items-center gap-2">
              <div className="flex relative items-center w-full">
                <FaCity className=" absolute left-1 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                <input
                  className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                  placeholder={`${t('home.hero.form.placeHolderCityName')}`}
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  type="city"
                  id="city"
                  name="city"
                />
              </div>
              <div className="flex relative items-center w-full">
                <FaTreeCity className=" absolute left-1 w-7 h-7 mr-2 text-gray-500 hover:shake" />
                <input
                  className="pl-12 rounded bg-gray-200 shadow-lg w-full p-2 placeholder:text-gr1"
                  placeholder={`${t('home.hero.form.placeHolderCompanyName')}`}
                  onChange={(e) => setCompany(e.target.value)}
                  value={company}
                  type="company"
                  id="company"
                  name="company"
                />
              </div>
            </div>

            <div className="flex relative items-center">
              <AiOutlineMessage className="absolute left-1 top-2 w-7 h-7 mr-2 text-gray-500 hover:shake" />
              <textarea
                className="pl-12 w-full  rounded bg-gray-200  shadow-lg   placeholder:text-gr1 "
                placeholder={`${t('home.hero.form.placeHolderMessage')}`}
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                id="message"
              ></textarea>
            </div>
            <button
              type="submit" // Change type to "submit" to trigger form submission
              className="text-white px-4 py-2 mb-2 rounded-lg bg-sk hover:bg-sk1 transition duration-200"
            >
              {t('home.hero.form.sendMessage')}
            </button>
          </div>
        </form>
      </div>


    </div>
  );
};

export default Hero;
