import { useState } from "react";
import { FaTimes } from "react-icons/fa"
import { Button } from "@chakra-ui/react"

import Image1 from '../../../../Assets/images/New Images/Moving_6.jpeg'

import { useTranslation } from 'react-i18next';

const Premium = () => {
    
    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    return (<div className="h-screen bg-[#eae8e1] overflow-clip border-indigo-500 border-1">

        <div className="flex   flex-row-reverse overflow-hidden">
            {/* first content */}
            <div className="">
                <div className=" my-36 mx-24   text-black text-5xl font-medium font-['Young Serif']   ">




                    <p class=" text-start heading"><span className="text-red-800 font-bold ">H<span className="text-blue-600">M</span></span><span className="font-bold text-green-800">Flytt</span></p>
                    <div className="w-full  flex justify-start mt-8">

                        <p className=" border-black border-2 w-20 text-center" ></p>

                    </div >
                    <div className="text-base my-5 text-start flex justify-center items-center">
                        <p className="w-full md:w-[400px]" >{t('homeCleaning.premium.paragraph1')}</p>
                    </div >
                    <div className="text-base text-start">
                        <p> {t('homeCleaning.premium.paragraph2Part1')} <span className="underline font-semibold">{t('homeCleaning.premium.paragraph2Part2')}</span> </p>
                    </div>
                </div>

            </div>


            {/* second content */}



            <div className='w-[800px] h-[550px] overflow-hidden'>
                <div className="w-[800px] h-[550px] flex justify-center items-end background-cover bg-no-repeat bg-cover overflow-hidden transition-transform transform hover:scale-105 duration-300 image-div relative" style={{backgroundImage:`url(${Image1})`}}>
                    <Button style={{ background: "black" }} onClick={() => setModalOpen(true)} className="h-[30px] rounded-lg px-4 pt-2 overflow-hidden relative bottom-[-50px] group cursor-pointer border-2 font-medium text-[16px] border-white text-black hover:scale-105 duration-[900ms] hover:border-[#003942] hover:text-[white] preview-button">
                        <span className="absolute w-72 h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-20 bg-white top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                        <span className="relative text-xl text-white transition duration-[900ms] group-hover:text-black ease mb-2">{t('generalComponents.preview')}</span>
                    </Button>
                </div>
            </div>

        </div>

        {modalOpen && (
            <div style={{zIndex: 9999}} className="z-5000 fixed top-[30px] left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                <div className="fadeIn bg-white p-4 rounded-lg relative z-5001">
                    <div onClick={() => setModalOpen(false)} className='absolute top-0 right-0 m-2 p-2 cursor-pointer rounded-full border-2 border-orange-600 text-orange-600 bg-white text-center'>
                    <FaTimes />
                    </div>
                    <img src={Image1} alt="Full Size" className="h-[500px] rounded-lg"/>
                    <button onClick={() => setModalOpen(false)}>{t('generalComponents.close')}</button>
                </div>
            </div>
        )}

    </div>
    );

}

export default Premium;