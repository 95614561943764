import { useState } from "react";
import { BiSolidChevronDown } from "react-icons/bi";
import { FaTimes } from "react-icons/fa"
import { Button } from "@chakra-ui/react"

import Image1 from '../../../../Assets/images/New Images/Moving_7.jpeg'
import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const Premium = () => {

    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);

    const [activeIndex, setActiveIndex] = useState();
    const handleSetIndex = (index) => {
        setActiveIndex(index);
    };
    const premium = [
        {
            title: " 1.",
            question: `${t('generalHandyman.premium.service.heading1')}`,
            answer: t('generalHandyman.premium.service.description1', { returnObjects: true }),
        },
        {
            title: " 2.",
            question: `${t('generalHandyman.premium.service.heading2')}`,
            answer: t('generalHandyman.premium.service.description2', { returnObjects: true }),
        },
        {
            title: " 3.",
            question: `${t('generalHandyman.premium.service.heading3')}`,
            answer: t('generalHandyman.premium.service.description3', { returnObjects: true }),
        },
        {
            title: " 4.",
            question: `${t('generalHandyman.premium.service.heading4')}`,
            answer: t('generalHandyman.premium.service.description4', { returnObjects: true }),
        },
        {
            title: " 5.",
            question: `${t('generalHandyman.premium.service.heading5')}`,
            answer: t('generalHandyman.premium.service.description5', { returnObjects: true }),
        },
        {
            title: " 6.",
            question: `${t('generalHandyman.premium.service.heading6')}`,
            answer: t('generalHandyman.premium.service.description6', { returnObjects: true }),
        },
        {
            title: " 7.",
            question: `${t('generalHandyman.premium.service.heading7')}`,
            answer: t('generalHandyman.premium.service.description7', { returnObjects: true }),
        },
        {
            title: " 8.",
            question: `${t('generalHandyman.premium.service.heading8')}`,
            answer: t('generalHandyman.premium.service.description8', { returnObjects: true }),
        }
    ];


    return (
    <div className="h-auto bg-[#e2c6bb] overflow-clip border-indigo-500 border-1 py-10 md:py-0">

        <div className="flex flex-col-reverse md:flex-row-reverse justify-between items-center overflow-hidden">
            {/* first content */}
            <div className="w-full md:w-1/2 flex flex-col justify-center items-center md:items-start">
                <div className="w-5/6 mt-4 ms-0 text-black text-5xl font-medium font-['Young Serif'] ">

                    <p class="text-center md:text-start"><span className="text-black font-bold ">{t('generalHandyman.premium.heading.headingPart1')} < br /> <span className="text-green-600"> {t('generalHandyman.premium.heading.headingPart2')}  </span></span>
                    </p>
                    <div className="w-full  flex justify-center md:justify-start mt-4">

                        <p className=" border-black border-2 w-40 text-center" ></p>

                    </div >
                    <div className="w-full flex flex-col justify-center items-center md:items-start my-2 text-center md:text-start">
                        {/* <Link to='/Generalhandyman1'> */}

                            {premium.map(({ title, question, answer }, i) => (
                                <div
                                    className="bg-white rounded-lg w-full p-1 my-2 shadow-lg hover:shadow-2xl group gap-8"
                                    onMouseLeave={() => setActiveIndex()}
                                    onMouseEnter={() => handleSetIndex(i)}
                                >
                                    <div className="flex justify-between items-center my-2.5  px-4 sm:px-8 h-10 cursor-pointer" >
                                        <h3 className={`lg:text-xl md:text-xl sm:text-xl text-xs  font-semibold mr-2 ${getTitleColor(i)}`}>{title}</h3>
                                        <h3 className={`lg:text-xl md:text-xl sm:text-xl text-xs  font-semibold mr-2 ${getTitleColor(i)}`}>{question}</h3>
                                        <div className="text-3xl group-hover:rotate-180 transition duration-1500 ml-auto">
                                            {/* Right Icon */}
                                            <BiSolidChevronDown className="w-8 h-8  border-2 rounded-full text-green-500 border-green-500  group-hover:border-yellow-400 group-hover:text-yellow-400 " />
                                        </div>
                                    </div>
                                    <div
                                        x-ref="tab"
                                        className={`border-l-2 rounded-bl-md border-yellow-500 dark:border-none overflow-hidden duration-[1500ms] transition-all ${activeIndex === i ? "max-h-[500px]" : "max-h-0 "
                                            }`}
                                    >
                                        <p className="text-start px-4 sm:px-8   text-xl font-light text-gray-700">
                                            {answer}
                                        </p>
                                    </div>
                                </div>

                            ))}
                        {/* </Link> */}
                        {/*  <p className="font-bold text-sm">Home Repairs:</p>
                        <p className="mt-1 text-sm">General repairs for walls, doors, windows, and ceilings.</p>

                        <p className="font-bold text-sm mt-1">Painting and Decorating:  </p>
                        <p className="mt-1">Interior and exterior painting, wallpapering, and decorative finishes.</p>
                        <p className="font-bold text-sm mt-1">Carpentry:</p>
                        <p className="mt-1">Installation and repair of cabinets, shelves, doors, and windows.</p>
                        <p className="font-bold text-sm mt-1">Furniture Assembly:</p>
                        <p className="mt-1">Assembly of flat-pack furniture from various retailers.</p>
                        <p className="font-bold text-sm mt-1">Plumbing Services:</p>
                        <p className="mt-1">Fixing leaks, installing faucets, repairing toilets, and unclogging drains.</p>
                        <p className="font-bold text-sm mt-1">Electrical Services: </p>
                        <p className="mt-1">Installing light fixtures, ceiling fans, outlets, and switches.</p>
                        <p className="font-bold text-sm mt-1">Fixture and Fitting Installation:</p>
                        <p className="mt-1">Hanging mirrors, shelves, curtain rods, and blinds.</p> */}
                        {/* <p className="font-bold text-sm mt-1">Gutter Cleaning and Repair: </p>
                        <p className="mt-1">Removing debris, fixing leaks, and ensuring proper drainage.</p>
                        <p className="font-bold text-sm mt-1">Drywall Repair: </p>
                        <p className="mt-1">Patching holes, repairing cracks, and smoothing out rough surfaces.</p>
                        <p className="font-bold text-sm mt-1">Appliance Installation and Repair: </p>
                        <p className="mt-1">Installing dishwashers, ovens, washing machines, and dryers.</p>
                        <p className="font-bold text-sm mt-1">Fence and Gate Repair: </p>
                        <p className="mt-1">Fixing damaged panels, adjusting hinges, and installing new fencing.</p>
 */}
                        <p></p>
                    </div >
                    {/*  <div className="text-base text-start">
                        <p> Read more about <span className="underline font-semibold">FSA Premium.</span> </p>
                    </div> */}
                </div>

            </div>


            {/* second content */}



            <div className='w-[800px] h-[300px] md:h-[550px] overflow-hidden my-10 md:mx-10'>
                <div className="w-[800px] h-[300px] md:h-[550px] flex justify-center items-end background-cover bg-no-repeat bg-cover overflow-hidden transition-transform transform hover:scale-105 duration-300 image-div relative" style={{backgroundImage:`url(${Image1})`}}>
                    <Button style={{ background: "black" }} onClick={() => setModalOpen(true)} className="h-[30px] rounded-lg px-4 pt-2 overflow-hidden relative bottom-[-50px] group cursor-pointer border-2 font-medium text-[16px] border-white text-black hover:scale-105 duration-[900ms] hover:border-[#003942] hover:text-[white] preview-button">
                        <span className="absolute w-72 h-0 transition-all duration-[900ms] origin-center rotate-45 -translate-x-20 bg-white top-1/2 group-hover:h-64 group-hover:-translate-y-32 ease"></span>
                        <span className="relative text-xl text-white transition duration-[900ms] group-hover:text-black ease mb-2">{t('generalComponents.preview')}</span>
                    </Button>
                </div>
            </div>

        </div> 

        {modalOpen && (
            <div style={{zIndex: 9999}} className="z-5000 fixed top-[30px] left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                <div className="fadeIn bg-white p-4 rounded-lg relative z-5001">
                    <div onClick={() => setModalOpen(false)} className='absolute top-0 right-0 m-2 p-2 cursor-pointer rounded-full border-2 border-orange-600 text-orange-600 bg-white text-center'>
                    <FaTimes />
                    </div>
                    <img src={Image1} alt="Full Size" className="h-[500px] rounded-lg"/>
                    <button onClick={() => setModalOpen(false)}>{t('generalComponents.close')}</button>
                </div>
            </div>
        )}

    </div>
    );
    function getTitleColor(index) {
        const colors = [
            "text-red-500",
            "text-blue-500",
            "text-green-500",
            "text-yellow-500",
            "text-pink-500",
            "text-purple-500",
            "text-indigo-500",
            "text-gray-500",
            // Additional colors
            "text-orange-500",
            "text-teal-500",
            "text-cyan-500",
            "text-amber-500",
            "text-lime-500",
            "text-gray-500",
            "text-green-500",
        ];
        return colors[index % colors.length];
    }
}

export default Premium;