import React, { useState, useEffect } from "react";
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import "../../../Assets/css/window_cleaning.css";
import CrossIcon from "../../Gernal_Components/CrossIcon";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { BiSolidChevronDown } from "react-icons/bi";
import Note from "../MoveoutCleaning/Note";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';
import emailjs from "emailjs-com";
import Faq1 from "../MoveoutCleaning/faq1";

import { useTranslation } from 'react-i18next';

const MovingOutService = () => {
  
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState();
  const handleSetIndex = (index) => {
    setActiveIndex(index);
  };
  /* const Note = [
    {
      question: "Note to Customers: Heavy Items Notification",
      answer: "Dear Valued Customer,As part of our commitment to ensuring a smooth and efficient moving experience for you, we kindly request your attention regarding the transportation of heavy items exceeding 100 kilograms. If you have heavy items such as furniture, Piano, machinery, or any other bulky belongings that weigh greater than 100 kilograms, we kindly ask that you inform us in advance during the booking process. This notification allows us to adequately prepare our moving team and ensure the safety of your items during transportation. Please be advised that the transportation of heavy items exceeding 100 kilograms may incur additional charges due to the specialized equipment and resources required for their safe handling and transportation. Once you have notified us of the presence of heavy items, we will provide you with a transparent overview of any additional costs associated with their transportation."
    }

  ] */

  const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

  const navigate = useNavigate()
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [toaddress, setToaddress] = useState("");
  const [fromaddress, setFromaddress] = useState("");
  const [sqm, setSqm] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [size, setSize] = useState("");
  const [streetAddress1, setStreetAddress1] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [floor, setFloor] = useState("")
  const [floor1, setFloor1] = useState("")
  const [walkingdistance, setWalkingdistance] = useState('')
  const [walkingdistance1, setWalkingdistance1] = useState('')
  const [message, setMessage] = useState('')


  const [handover, setHandover] = useState("open");
  const [wantCleaning, setWantCleaning] = useState("yes");

  const [ovenCleaning, setOvenCleaning] = useState(0);
  const [fridgeCleaning, setFridgeCleaning] = useState(0);
  const [ovenCleaning1, setOvenCleaning1] = useState(0);
  const [fridgeCleaning1, setFridgeCleaning1] = useState(0);
  const [ovenCleaning2, setOvenCleaning2] = useState(0);
  const [fridgeCleaning2, setFridgeCleaning2] = useState(0);
  const [ovenCleaning3, setOvenCleaning3] = useState(0);
  const [fridgeCleaning3, setFridgeCleaning3] = useState(0);
  const [ovenCleaning4, setOvenCleaning4] = useState(0);
  const [fridgeCleaning4, setFridgeCleaning4] = useState(0);
  const [ovenCleaning5, setOvenCleaning5] = useState(0);
  const [ovenCleaning6, setOvenCleaning6] = useState(0);
  const [ovenCleaning7, setOvenCleaning7] = useState(0);
  const [ovenCleaning8, setOvenCleaning8] = useState(0);

  const [ovenCleaning11, setOvenCleaning11] = useState(0);
  const [ovenCleaning12, setOvenCleaning12] = useState(0);
  const [ovenCleaning13, setOvenCleaning13] = useState(0);
  const [ovenCleaning14, setOvenCleaning14] = useState(0);
  const [ovenCleaning15, setOvenCleaning15] = useState(0);
  const [ovenCleaning16, setOvenCleaning16] = useState(0);
  const [ovenCleaning17, setOvenCleaning17] = useState(0);
  const [ovenCleaning18, setOvenCleaning18] = useState(0);



  const [fridgeCleaning5, setFridgeCleaning5] = useState(0);
  const [fridgeCleaning6, setFridgeCleaning6] = useState(0);
  const [fridgeCleaning7, setFridgeCleaning7] = useState(0);
  const [fridgeCleaning8, setFridgeCleaning8] = useState(0);

  const [fridgeCleaning11, setFridgeCleaning11] = useState(0);
  const [fridgeCleaning12, setFridgeCleaning12] = useState(0);
  const [fridgeCleaning13, setFridgeCleaning13] = useState(0);
  const [fridgeCleaning14, setFridgeCleaning14] = useState(0);
  const [fridgeCleaning15, setFridgeCleaning15] = useState(0);
  const [fridgeCleaning16, setFridgeCleaning16] = useState(0);
  const [fridgeCleaning17, setFridgeCleaning17] = useState(0);
  const [fridgeCleaning18, setFridgeCleaning18] = useState(0);


  const [windowCleaning, setWindowCleaning] = useState(false);
  const [storstadningPrice, setStorstadningPrice] = useState("");
  const [total, setTotal] = useState(0);
  const [totalItem, setTotalItem] = useState("")
  const [totalbed, setTotalBed] = useState("")
  const [totalItem1, setTotalItem1] = useState("")
  const [totalbed1, setTotalBed1] = useState("")
  const handleHandoverChange = (value) => {
    setHandover(value);
  };

  const handleCleaningChange = (value) => {
    setWantCleaning(value);
  };
  const isValidEmail = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (
      !toaddress ||
      !streetAddress ||
      !floor1 ||
      !walkingdistance ||
      !fromaddress ||
      !streetAddress1 ||
      !floor1 ||
      !walkingdistance1 ||
      !sqm ||
      !selectedDate ||
      !selectedTime ||
      !firstName ||
      !lastName ||
      !email ||
      !message ||
      !total

    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }


    try {
      const messageBody = `
             New Moving Service:
             
             Moving From:
             Street address: ${toaddress}
             Residence: ${streetAddress}
             Floor: ${floor}
             Walking Distance: ${walkingdistance}

             Moving To: 
             Street address: ${fromaddress}
             Residence: ${streetAddress1}
             Floor: ${floor1}
             Walking Distance: ${walkingdistance1}
             Sqm For Moving Service: ${sqm}
             Date : ${selectedDate}
             Time : ${selectedTime}
             First Name : ${firstName}
             Last Name:${lastName}
             Email:${email}
             Message:${message}

             Total Price:${total} SEK

             Thank you for your interest in our services! We'll be in touch shortly to discuss how we can assist you further.

             
         `;
      const templateParams = {
        to_name: `${firstName} ${lastName}`,
        from_name: "HMFLYTT Team",
        to_email: email,
        subject: "New General Handyman Request",
        message: messageBody,
      };
      const response = await emailjs.send(
        "service_tkepj1s",
        "template_2qj2i3r",
        templateParams,
        "H-hG0vqXi4PYjR7Ld"
      );
      console.log("Email sent successfully:", response);
      if (response) {
        toast.success(`${firstName} ${lastName} Your Request Sent Successfully`);
        setToaddress("")
        setStreetAddress("")
        setFloor1("")
        setWalkingdistance("")
        setFromaddress("")
        setSize("")
        setStreetAddress1("")
        setFloor1("")
        setWalkingdistance1("")
        setSqm("")
        setSelectedDate("")
        setSelectedTime("")
        setFirstName("")
        setLastName("")
        setEmail("")
        setMessage("")
        setTotal("")   
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };


  useEffect(() => {
    const discountCodeValue = 221;

    const ovenCleaningCost = ovenCleaning * 563;
    const fridgeCleaningCost = fridgeCleaning * 299;
    const ovenCleaningCost1 = ovenCleaning1 * 553;
    const ovenCleaningCost2 = ovenCleaning2 * 553;
    const ovenCleaningCost3 = ovenCleaning3 * 553;
    const ovenCleaningCost4 = ovenCleaning4 * 553;
    const ovenCleaningCost5 = ovenCleaning5 * 553;
    const ovenCleaningCost6 = ovenCleaning6 * 553;
    const ovenCleaningCost7 = ovenCleaning7 * 553;
    const ovenCleaningCost8 = ovenCleaning8 * 553;

    const fridgeCleaningCost1 = fridgeCleaning1 * 329;
    const fridgeCleaningCost2 = fridgeCleaning2 * 329;
    const fridgeCleaningCost3 = fridgeCleaning3 * 329;
    const fridgeCleaningCost4 = fridgeCleaning4 * 329;
    const fridgeCleaningCost5 = fridgeCleaning5 * 329;
    const fridgeCleaningCost6 = fridgeCleaning6 * 329;
    const fridgeCleaningCost7 = fridgeCleaning7 * 329;
    const fridgeCleaningCost8 = fridgeCleaning8 * 329;

    const windowCleaningCost = windowCleaning ? 176 : 0;

    const total = storstadningPrice;


    setTotal(total);
  }, [storstadningPrice, windowCleaning]);
  const hanldesqm = (event) => {
    const newSqm = event.target.value;
    setSqm(newSqm);
  };
  useEffect(() => {
    console.log(sqm); // Log updated sqm value
    const formula = ((2000 + 100 * sqm) * 0.5);
    setTotal(formula);
    console.log(total); // Log updated total value
  }, [sqm]);



  const handlechangeaddresss = (value) => {
    setFromaddress(value);
    console.log(value);
  };

  const handlechangeaddress = (value) => {
    setToaddress(value);
    console.log(value);
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  // Event handler for time input change
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };
  const handleAddress = (value) => {
    setStreetAddress(value);
    console.log(value);
  };

  const handleSize = (value) => {
    setSize(value);
    console.log(value);
  };
  const handleAddress1 = (value) => {
    setStreetAddress1(value);
    console.log(value);
  };

  const handleFloor = (value) => {
    setFloor(value);
    console.log(value);
  }; const handleFloor1 = (value) => {
    setFloor1(value);
    console.log(value);
  };
  const handleWalkingdistance = (value) => {
    setWalkingdistance(value);
    console.log(value);
  };
  const handleWalkingdistance1 = (value) => {
    setWalkingdistance1(value);
    console.log(value);
  };
  return (
    <div className="flex justify-center items-center w-full pt-10 mb-[950px] lg:mb-20" >
      <div className="flex flex-col lg:flex-row w-full lg:w-[90%] justify-center items-center lg:items-start" >
        <div className="text-center px-2">
          <div className="flex justify-end hover:shake">
            <CrossIcon handleClickCrossIcon={() => navigate("/MovingService")} />
          </div>
          <div
            className=" mt-2 font-normal text-5xl"
            style={{ fontFamily: "Tiempos Headline,serif" }}
          >
            {t('contactPages.movingService')}
          </div>


          <div className="w-full mt-8  flex justify-center items-center mb-5">
            <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
          </div>

          <div className="mt-3 rounded-lg p-4">
            <label
              htmlFor="message"
              className="text-2xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.movingFrom')}
            </label>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.streetAddress')}
            </label>
            <div className="flex items-center mb-3">
              <input
                type="text"
                value={toaddress}
                onChange={(e) => handlechangeaddress(e.target.value)}
                id="text"
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>

            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.typeOfResidence')}
            </label>
            <div className="flex items-center mb-3">

              <select value={streetAddress} id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleAddress(e.target.value)} >
                <option value="" disabled selected hidden>Select an option</option>

                <option value="Villa/Terraced house
">Villa/Terraced house
                </option>
                <option value="Apartment">Apartment</option>
                <option value="Storage unit">Storage unit</option>
              </select>
            </div>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.guideLineForEstimating')}

            </label>
            <div className="flex items-center mb-3">

              <select value={size} id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleSize(e.target.value)} >
                <option value="" disabled selected hidden>Select an option</option>

                <option value="Studio Apartment or Small 1-Bedroom Apartment (up to 40 sqm)
">Studio Apartment or Small 1-Bedroom Apartment (up to 40 sqm)
                </option>
                <option value="Medium 1-Bedroom or Small 2-Bedroom Apartment (40-60 sqm)">Medium 1-Bedroom or Small 2-Bedroom Apartment (40-60 sqm)</option>
                <option value="Large 2-Bedroom or Small 3-Bedroom Apartment (60-80 sqm)">Large 2-Bedroom or Small 3-Bedroom Apartment (60-80 sqm)</option>
                <option value="Medium 3-Bedroom or Small 4-Bedroom House (80-100 sqm)">Medium 3-Bedroom or Small 4-Bedroom House (80-100 sqm)</option>

                <option value="Large 4-Bedroom or Small 5-Bedroom House (100-120 sqm)">Large 4-Bedroom or Small 5-Bedroom House (100-120 sqm)</option>
                <option value="Extra-Large 5-Bedroom or Larger House (120+ sqm">Extra-Large 5-Bedroom or Larger House (120+ sqm)</option>

              </select>
            </div>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.floor')}
            </label>
            <div className="flex items-center mb-3">

              <select value={floor} id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleFloor(e.target.value)}>
                <option value="" disabled selected hidden>Select an option</option>

                <option value="First">First</option>
                <option value="Scecond">Second</option>
                <option value="Third">Third</option>
                <option value="Fourth">Fourth</option>

                <option value="Fifth">Fifth</option>

                <option value="Sixth">Sixth</option>

                <option value="Seventh">Seventh</option>
                <option value="Higher than Seven">Higher than Seven</option>


              </select>
            </div>

            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.walkingDistance')}
            </label>

            <div className="flex items-center mb-3">

              <select value={walkingdistance} id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleWalkingdistance(e.target.value)} >
                <option value="" disabled selected hidden>Select an option</option>

                <option value="2-10m">2-10m</option>
                <option value="10-20m">10-20m</option>
                <option value="20-40m">20-40m</option>
                <option value="50-100m">50-100m</option>

                <option value="100-200m">100-200m</option>

                <option value="More than 200m">More than 200m</option>



              </select>
            </div>

            <label
              htmlFor="message"
              className="text-2xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.movingTo')}
            </label>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.streetAddress')}
            </label>
            <div className="flex items-center mb-3">
              <input
                type="text"
                value={fromaddress}

                onChange={(e) => handlechangeaddresss(e.target.value)}
                id="text"
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>

            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.typeOfResidence')}
            </label>
            <div className="flex items-center mb-3">

              <select value={streetAddress1} id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleAddress1(e.target.value)} >
                <option value="" disabled selected hidden>Select an option</option>

                <option value="Villa/Terraced house
">Villa/Terraced house
                </option>
                <option value="Apartment">Apartment</option>
                <option value="Storage unit">Storage unit</option>
              </select>
            </div>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.floor')}
            </label>
            <div className="flex items-center mb-3">

              <select value={floor1} id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleFloor1(e.target.value)} >
                <option value="" disabled selected hidden>Select an option</option>

                <option value="First">First</option>
                <option value="Scecond">Second</option>
                <option value="Third">Third</option>
                <option value="Fourth">Fourth</option>

                <option value="Fifth">Fifth</option>

                <option value="Sixth">Sixth</option>

                <option value="Seventh">Seventh</option>
                <option value="Higher than Seven">Higher than Seven</option>


              </select>
            </div>

            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.walkingDistance')}
            </label>

            <div className="flex items-center mb-3">

              <select value={walkingdistance1} id="residenceDropdown" className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                onChange={(e) => handleWalkingdistance1(e.target.value)}>
                <option value="" disabled selected hidden>Select an option</option>

                <option value="2-10m">2-10m</option>
                <option value="10-20m">10-20m</option>
                <option value="20-40m">20-40m</option>
                <option value="50-100m">50-100m</option>

                <option value="100-200m">100-200m</option>

                <option value="More than 200m">More than 200m</option>



              </select>
            </div>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.sqmForMovingService')}
            </label>

            <div className="flex items-center mb-3">
              <input
                type="number"
                value={sqm}
                onChange={hanldesqm}
                id="number"
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>

            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.date')}:
            </label>
            <div className="flex items-center mb-3">
              <input
                type="date"
                id="date"
                value={selectedDate}
                onChange={handleDateChange}
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1"
            >
              {t('contactPages.time')}
            </label>
            <div className="flex items-center mb-3">
              <input
                type="time"
                id="time"
                value={selectedTime}
                onChange={handleTimeChange}
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>

            {/* Add First Name Input */}
            <label htmlFor="firstName" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
              {t('contactPages.firstName')}:
            </label>
            <input
              type="text"
              id="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}

              name="firstName"
              className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              placeholder="Enter your first name"
            />

            {/* Add Last Name Input */}
            <label htmlFor="lastName" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
              {t('contactPages.lastName')}:
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}

              className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              placeholder="Enter your last name"
            />

            {/* Add Email Input */}
            <label htmlFor="email" className="text-xl font-semibold flex justify-left mb-2 font-normal ms-1">
              {t('contactPages.email')}:
            </label>
            <input
              type="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}

              name="email"
              className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              placeholder="Enter your email"
            />
          </div>

          <div className="mt-[-2px] rounded-lg p-2">
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-1 font-normal ms-1"
            >
              {t('contactPages.specificMessage')}
            </label>
            <div className="flex items-center mb-3">
              <input
                type="text"
                name="message"
                onChange={(e) => setMessage(e.target.value)}
                id="message"
                value={message}
                className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>
          </div>


          <div className="p-2">
            <div
              className="mt-10"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <button
                style={{ padding: "15px 50px 15px 50px" }}
                className="bg-[#000] font-bold uppercase text-white p-5 rounded text-bold"
                onClick={handleSubmit}
              
              >
                {t('contactPages.next')}
              </button>
            </div>
          </div>
        </div>
        <div
          className="w-full lg:w-[400px] h-[500px] mt-20 pb-5 bg-[#d1dce7] mx-6 lg:mx-0"
        >
          <div className="flex-col mt-5 px-5">
            <p
              className="text-2xl text-normal text-[#003b5c] text-left"
              style={{ fontFamily: "Tiempos Headline,serif" }}
            >
              {t('contactPages.yourBooking')}
            </p>
          </div>
          <div className="flex mt-5 px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.movingFrom')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{toaddress}</p>
          </div>
          <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
            <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
          </div>
          <div className="flex  px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.date')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{selectedDate}</p>
          </div>
          <div className="flex  px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.time')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{selectedTime}</p>
          </div>

          <div className="flex mt-5 px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.movingTo')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{fromaddress}</p>
          </div>
          <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
            <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
          </div>
          <div className="flex  px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.date')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{selectedDate}</p>
          </div>
          <div className="flex  px-5">
            <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
            {t('contactPages.time')}
            </p>
            <p className="text-gl font-bold text-[#003b5c]">{selectedTime}</p>
          </div>

          {/* <div className="mt-5 px-5">
            <p className="text-xl font-semibold text-[#003b5c] text-left">
              Date and time
            </p>
            <p className="text-xl text-left font-semibold">-</p>
          </div> */}
          <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
            <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
          </div>
          <div className="flex mt-5 px-5">
            <div className="flex-1">
              <p className="text-lg text-[#003b5c] text-left flex-1">Total</p>
              <p className="text-sm text-[#003b5c] text-left flex-1 ms-1">
              {t('contactPages.withRUTReduction')}
              </p>
            </div>
            <p className="text-xl font-semibold text-[#003b5c] my-auto">
              {total} SEK
            </p>
          </div>
          <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
            <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
          </div>
          <div className="flex mt-5 mb-3 px-5">
            <p
              className="text-normal text-normal underline text-[#003b5c] text-left"
              style={{ display: "inline-block" }}
            >
              {t('contactPages.addDiscountCode')}
            </p>

          </div>
          <Faq1 />
        </div>

      </div>
    </div >
  );
};

export default MovingOutService;