import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const Cleaningtoloc = () => {

    const { t } = useTranslation();

    return (
        <div>
            <div className="bg-[#fff]   relative  font-['Roboto']">

                <div className="pt-36 text-5xl font-bold"><span className='text-red-500'>{t('home.cleaningToLocation.headingPart1')}</span> {t('home.cleaningToLocation.headingPart2')}</div>
                <div className="w-full mt-8  flex justify-center items-center">

                    <p className=" border-black border-2 w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100" ></p>

                </div>

                {/*  <div className="flex justify-center items-center flex-col xl:mx-10 lg:mx-7 2xl:mx-80 mt-16 h-auto pb-32 ">
                    <div className="flex flex-col lg:flex-row items-center w-fit" >


                        <div className="lg:mr-20  mr-0">
                            <div class=" flex items-start  relative">
                                <Link href="/Home_clean">
                                    <img src='img/wi4.svg'
                                        class="h-12 inline-block hover:shake" alt="" />
                                </Link>


                                <p className="inline-block text-2xl  ml-8">Home Cleaning</p>
                                <Link href="/Home_clean">
                                    <p className="text-center text-4xl   absolute  end-0 bottom-3  cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>
                            <p className="border-[#d5d2c4] border-b-2  my-4  pl-[450px]" ></p>
                        </div>
                        <div>
                            <div class=" flex items-start  relative">
                                <Link href="/Home_clean">
                                    <img className=" inline-block  h-12"
                                        src='img/wi2.svg'
                                        class="h-12 inline-block hover:shake" alt="" />
                                </Link>
                                <p className="text-2xl inline-block  ml-8">Gardening help</p>
                                <Link href="/Home_clean">
                                    <p className="text-center text-4xl   absolute end-0 bottom-3 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>


                            <p className="border-[#d5d2c4] border-b-2  my-4  pl-[450px]" ></p>


                        </div>

                    </div>


                    <div className="flex flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <Link href="/Deep_Clean">
                                    <img className="h-12 inline-block"
                                        src='img/wi.svg'
                                        class="h-12 inline-block hover:shake" alt="" />
                                </Link>
                                <p className="text-2xl inline-block ml-8">Deep Cleaning</p>
                                <Link href="/Deep_Clean">
                                    <p className="text-center text-4xl   absolute  end-0 top-1 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>
                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px] flex-wrap " ></p>
                        </div>
                        <div className="">

                            <div className="flex items-end relative">
                                <img className="h-12 inline-block"
                                    src='img/wi1.svg'
                                    class="h-12 inline-block hover:shake" alt="" />
                                <p className="text-2xl inline-block ml-8">General handyman</p>
                                <p className="text-center text-4xl   absolute bottom-3 end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </div>


                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px] flex-wrap" ></p>
                        </div>
                    </div>
                    <div className="flex  flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">

                            <div className="flex items-start relative">
                                <Link href="/Window_clean">
                                    <img className="h-12 inline-block"
                                        src='img/ha.svg'
                                        class="h-12 inline-block hover:shake" alt="" />
                                </Link>
                                <p className="inline-block text-2xl ml-8">Window Cleaning</p>
                                <Link href="/Window_clean"></Link>
                                <p className="text-center text-4xl   absolute  end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                <Link />
                            </div>




                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px]" ></p>
                        </div>


                        <div>
                            <div className="flex items-start relative flex-wrap">
                                <img className="h-10 inline-block"
                                    src='img/n.svg'
                                    class="h-10 inline-block hover:shake" alt="" />

                                <p className="text-2xl inline-block ml-8">Moving Services</p>
                                <p className="text-center text-4xl top-2  absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </div>

                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px]" ></p>
                        </div>

                    </div>


                    <div className="flex flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <img className="h-12 inline-block"
                                    src='img/n2.svg'
                                    class="h-12 inline-block hover:shake" alt="" />
                                <p className="text-2xl inline-block ml-8 ">Recycling</p>
                                <p className="text-center text-4xl   absolute end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                            </div>


                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pl-[450px]" ></p>
                        </div>
                        <div>
                            <div className="flex items-start relative">
                                <Link href="/Moving_Home">
                                    <img className="h-12 inline-block"
                                        src='img/de3.svg'
                                        class="h-10 inline-block hover:shake" alt="" />
                                </Link>
                                <p className="text-2xl inline-block ml-8">Moving out Cleaning</p>
                                <Link href="/Moving_Home">
                                    <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>

                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center pr-[450px]" ></p>
                        </div>

                    </div>


                </div> */}
                <div className="flex justify-center items-center flex-col xl:mx-10 lg:mx-7 2xl:mx-80 mt-16 h-auto">
                    <div className="flex flex-col lg:flex-row items-center w-fit" >


                        <div className="lg:mr-20  mr-0">
                            <div class=" flex items-start  relative">
                                <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                    <img src='img/ha.svg'
                                        class="w-8 inline-block hover:shake" alt="" />



                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('home.cleaningToLocation.linkHomeCleaning')}</p>

                                    <p className="text-center text-4xl   absolute  end-0 bottom-3  cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>
                            <p className="border-[#d5d2c4] border-b-2  my-4  md:pl-[450px]" ></p>
                        </div>
                        <div>
                            <div class=" flex items-start  relative">
                                <Link to="/home_Renovation" onClick={() => window.scrollTo(0, 0)}>
                                    <img className=" inline-block  h-12"
                                        src='img/de3.svg'
                                        class="w-8 inline-block hover:shake" alt="" />

                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('home.cleaningToLocation.linkHomeRenovation')}</p>

                                    <p className="text-center text-4xl   absolute end-0 bottom-3 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>


                            <p className="border-[#d5d2c4] border-b-2  my-4  md:pl-[450px]" ></p>


                        </div>

                    </div>


                    <div className="flex flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <Link to="/Deep_Clean" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="h-12 inline-block"
                                        src='img/f.svg'
                                        class="w-10 inline-block hover:shake" alt="" />

                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('home.cleaningToLocation.linkDeepCleaning')}</p>

                                    <p className="text-center text-4xl   absolute  end-0 top-1 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>
                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pl-[450px] flex-wrap " ></p>
                        </div>
                        <div className="">

                            <div className="flex items-end relative">
                                <Link to="/Generalhandyman" onClick={() => window.scrollTo(0, 0)}>

                                    <img className="h-12 inline-block"
                                        src='img/genral.svg'
                                        class="w-8 inline-block hover:shake" alt="" />
                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('home.cleaningToLocation.linkGeneralHandyman')}</p>
                                    <p className="text-center text-4xl   absolute bottom-3 end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>


                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pr-[450px] flex-wrap" ></p>
                        </div>
                    </div>
                    <div className="flex  flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">

                            <div className="flex items-start relative">
                                <Link to="/Window_clean" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="h-12 inline-block"
                                        src='img/wi2.svg'
                                        class="h-10 inline-block hover:shake" alt="" />

                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('home.cleaningToLocation.linkWindowCleaning')}</p>

                                    <p className="text-center text-4xl   absolute  end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>




                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pl-[450px]" ></p>
                        </div>


                        <div>
                            <div className="flex items-start relative flex-wrap">
                                <Link to="/MovingService" onClick={() => window.scrollTo(0, 0)}>

                                    <img className="h-10 inline-block"
                                        src='img/t.svg'
                                        class="w-10 inline-block hover:shake" alt="" />

                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('home.cleaningToLocation.linkMovingServices')}</p>
                                    <p className="text-center text-4xl top-2  absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>

                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pr-[450px]" ></p>
                        </div>

                    </div>


                    <div className="flex flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <Link to="/Dödsbo" onClick={() => window.scrollTo(0, 0)}>

                                    <img className="h-12 inline-block"
                                        src='img/n.svg'
                                        class="w-8 inline-block hover:shake" alt="" />
                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">Dödsbo</p>
                                    <p className="text-center text-4xl   absolute end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>


                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pl-[450px]" ></p>
                        </div>
                        <div>
                            <div className="flex items-start relative">
                                <Link to="/Move_Out_Cleaning" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="h-12 inline-block"
                                        src='img/n3.svg'
                                        class="h-10 inline-block hover:shake" alt="" />

                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('home.cleaningToLocation.linkMovingOutCleaning')}</p>

                                    <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>

                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pr-[450px]" ></p>
                        </div>


                    </div>

                    <div>

                    </div>

                </div>

            </div>
        </div>

    );

}
export default Cleaningtoloc;