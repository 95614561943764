import React, { useEffect, useState } from "react";
import useTranslatePage from './../../Gernal_Components/TranslatePage';
import Loader from './../../Gernal_Components/Loader';

import "../../../Assets/css/window_cleaning.css";
import CrossIcon from "../../Gernal_Components/CrossIcon";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "emailjs-com";

import { useTranslation } from 'react-i18next';

const DeepCleaning = () => {

  const { t } = useTranslation();

  const [translatePage, loading] = useTranslatePage();

    useEffect(() => {
        const lang = localStorage.getItem('language');
        if (lang) {
        translatePage(lang);
        }
    }, []);

  const navigate = useNavigate();
  const [handover, setHandover] = useState("open");
  const [message, setMessage] = useState("");

  const [wantCleaning, setWantCleaning] = useState("yes");
  const [sqm, setSqm] = useState(0);
  const [ovenCleaning, setOvenCleaning] = useState(0);
  const [fridgeCleaning, setFridgeCleaning] = useState(0);
  const [windowCleaning, setWindowCleaning] = useState(false);
  const [storstadningPrice, setStorstadningPrice] = useState(2537);
  const [total, setTotal] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleHandoverChange = (value) => {
    setHandover(value);
  };

  const handleCleaningChange = (value) => {
    setWantCleaning(value);
  };

  const calculatePrice = (sqm) => {
    if (sqm >= 1 && sqm <= 50) {
      return 1405;
    } else if (sqm >= 51 && sqm <= 55) {
      return 1620;
    } else if (sqm >= 56 && sqm <= 65) {
      return 1725;
    } else if (sqm >= 66 && sqm <= 70) {
      return 1935;
    } else if (sqm >= 71 && sqm <= 75) {
      return 2150;
    } else if (sqm >= 76 && sqm <= 80) {
      return 2255;
    } else if (sqm >= 81 && sqm <= 90) {
      return 2465;
    } else if (sqm >= 91 && sqm <= 100) {
      return 2730;
    } else if (sqm >= 101 && sqm <= 105) {
      return 2940;
    } else if (sqm >= 106 && sqm <= 120) {
      return 3105;
    } else if (sqm >= 121 && sqm <= 140) {
      return 3375;
    } else if (sqm >= 141 && sqm <= 150) {
      return 3635;
    } else if (sqm >= 151 && sqm <= 160) {
      return 3825;
    } else if (sqm >= 161 && sqm <= 180) {
      return 4220;
    } else if (sqm >= 181 && sqm <= 200) {
      return 4440;
    } else {
      // Handle cases where sqm is out of defined ranges
      return 1405; // or any default value
    }
  };
  const handleWindowCleaningChange = () => {
    setWindowCleaning(!windowCleaning);
  };

  useEffect(() => {
    const price = calculatePrice(sqm);
    const ovenCleaningCost = ovenCleaning * 490;
    const fridgeCleaningCost = fridgeCleaning * 260;
    const windowCleaningCost = windowCleaning ? 350 : 0;

    const updatedStorstadningPrice =
      price + ovenCleaningCost + fridgeCleaningCost + windowCleaningCost;
    setStorstadningPrice(updatedStorstadningPrice);
  }, [sqm, ovenCleaning, fridgeCleaning, windowCleaning]);

  useEffect(() => {
    const discountCodeValue = 221;

    const ovenCleaningCost = ovenCleaning * 563;
    const fridgeCleaningCost = fridgeCleaning * 299;
    const windowCleaningCost = windowCleaning ? 176 : 0;

    const total = storstadningPrice;

    setTotal(total);
  }, [storstadningPrice, windowCleaning]);
  const isValidEmail = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (
      /* ! ||
      !\ ||

      ! || */
      !sqm ||
      !wantCleaning ||
      !ovenCleaning ||
      !fridgeCleaning ||
      !message||
      
      !firstName ||
      !lastName ||
      !email ||
      !total


      /* 
      !confirmEmail ||
      !campaignCode ||
      !kind ||
      !agreeToOffers */
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    /* if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (email !== confirmEmail) {
      toast.error("Email and Confirm Email must match.");
      return;
    } */
    try {
      const messageBody = `
      Deep Cleaning Request:
        
            Size of Home: ${sqm},
            Window Cleaning: ${wantCleaning}
            Oven Cleaning : ${ovenCleaning}
            Fridge cleaning: ${fridgeCleaning}
            Message : ${message}

            Total : ${total}

           Thank you for your interest in our services! We'll be in touch shortly to discuss how we can assist you further.

            
        `;

      const templateParams = {
        to_name: `${firstName} ${lastName}`,
        from_name: "HMFLYTT Team",
        to_email: email,
        subject: "Deep Cleaning Request",
        message: messageBody,
      };

      const response = await emailjs.send(
        "service_tkepj1s",
        "template_2qj2i3r",
        templateParams,
        "H-hG0vqXi4PYjR7Ld"
      );
      console.log("Email sent successfully:", response);
      if (response) {
        toast.success("Deep Cleaning Request Sent Successfully");
        
        setSqm("")
        setWantCleaning("")
        setOvenCleaning("")
        setFridgeCleaning("")
        setMessage("")
        setTotal("")

        document.getElementById("windowCleaning").checked = false;
        document.getElementById("open").checked = false;
        document.getElementById("leave").checked = false;
        document.getElementById("customer").checked = false;
        document.getElementById("cat").checked = false;
        document.getElementById("dog").checked = false;
        document.getElementById("other").checked = false;
      }
    } catch (error) {
      console.error("Error sending email:", error);
/*             toast.error("Something Went wrong please try again");
*/        }
  };
  return (
    <div
      className="flex justify-center items-center w-full pt-10 mb-20"
    >
      <div
        className="flex flex-col lg:flex-row w-full lg:w-[70%] justify-center items-center lg:items-start"
      >
        <div className="text-center px-2 w-2/1">
          <div className="flex justify-end hover:shake">
            <CrossIcon handleClickCrossIcon={() => navigate("/Deep_Clean")} />
          </div>
          <div
            className=" mt-2 font-normal text-5xl"
            style={{ fontFamily: "Tiempos Headline,serif" }}
          >
            {t('contactPages.deepCleaning')}
          </div>
          <div className="w-full mt-8  flex justify-center items-center mb-5">
            <p className=" border-black border w-20 text-center hover:w-[400PX] hover:border-gray-500  transition-all duration-300 transform origin-center scale-100"></p>
          </div>
          <div className="mt-3 rounded-lg p-4">
            <label
              htmlFor="sqm"
              className="text-xl font-semibold flex justify-left mb-2 font-normal"
            >
              {t('contactPages.livingAreaSQM')}
            </label>
            <div className="flex items-center mb-6">
              <input
                type="number"
                name="sqm"
                value={sqm}
                onChange={(e) =>
                  setSqm(e.target.value < 0 ? 0 : e.target.value)
                }
                id="sqm"
                className="border w-full rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>
          </div>

          <div className="mb-4 pr-10 my-auto flex p-4">
            <input
              type="checkbox"
              id="windowCleaning"
              name="windowCleaning"
              checked={windowCleaning}
              onChange={handleWindowCleaningChange}
              className="ms-2 mr-3 h-7 w-7 my-auto flex text-start"
            />
            <label
              htmlFor="windowCleaning"
              style={{ fontFamily: "Tiempos Headline,serif" }}
              className="text-xl flex text-start"
            >
              {t('contactPages.iDoNotHaveAVacuumCleaner')}
            </label>
          </div>

          <div className="p-4">
            <div className="flex items-center justify-between mb-2">
              <p className="text-xl font-semibold">
                {t('contactPages.doYouWantWindowCleaning')}
              </p>
            </div>
            <div className="border rounded-md mb-2 p-5 bg-white border-[#d5d2c4]">
              {/* Yes Radio Button */}
              <div className="flex items-center justify-between mb-2">
                <label
                  htmlFor="yes"
                  className="cursor-pointer flex items-center justify-between w-full"
                >
                  <p className="text-xl font-normal">{t('contactPages.yes')}</p>
                  <div
                    className={`w-7 h-7 rounded-full border border-gray-300 flex items-center justify-center ${wantCleaning === "yes" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {wantCleaning === "yes" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  id="yes"
                  name="cleaning"
                  checked={wantCleaning === "yes"}
                  onChange={() => handleCleaningChange("yes")}
                  className="hidden"
                />
              </div>

              <p className="mb-2 border-[#d6d2c4] border"></p>

              {/* No Radio Button */}
              <div className="flex">
                <label
                  className="text-xl cursor-pointer flex justify-between items-center mt-1 w-full my-auto font-normal"
                  htmlFor="no"
                >
                  <p className="text-left">{t('contactPages.no')}</p>
                  <div
                    className={`w-7 h-7 rounded-full border border-gray-300 flex items-center justify-center ${wantCleaning === "no" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {wantCleaning === "no" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  id="no"
                  name="cleaning"
                  value={"no"}
                  checked={wantCleaning === "no"}
                  onChange={() => handleCleaningChange("no")}
                  className="hidden"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between mb-2 ml-4">
            <p className="text-xl font-bold">{t('contactPages.anythingElse')}</p>
          </div>
          <div className="flex p-4">
            <div className="flex">
              <p className="text-2xl font-normal">{t('contactPages.ovenCleaning')}</p>
            </div>
            <div className="flex items-center" style={{ marginLeft: "auto" }}>
              <button
                className="negative-icon opacity-40 "
                onClick={() =>
                  setOvenCleaning(ovenCleaning > 0 ? ovenCleaning - 1 : 0)
                }
              >
                -
              </button>
              <p className="text-2xl font-semibold mx-4"> {ovenCleaning} </p>
              <button
                className="plus-icon bg-black"
                onClick={() => setOvenCleaning(ovenCleaning + 1)}
              >
                +
              </button>
            </div>
          </div>
          <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
          <div className="flex p-4">
            <div className="flex">
              <p className="text-2xl font-normal">{t('contactPages.fridgeCleaning')}</p>
            </div>
            <div className="flex items-center" style={{ marginLeft: "auto" }}>
              <button
                className="negative-icon opacity-40 "
                onClick={() =>
                  setFridgeCleaning(fridgeCleaning > 0 ? fridgeCleaning - 1 : 0)
                }
              >
                -
              </button>
              <p className="text-2xl font-semibold mx-4">{fridgeCleaning}</p>
              <button
                className="plus-icon bg-black"
                onClick={() => setFridgeCleaning(fridgeCleaning + 1)}
              >
                +
              </button>
            </div>
            
          </div>
          
          <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
          <div className="mt-3 rounded-lg p-4">

                        {/* Add First Name Input */}
                        <label htmlFor="firstName" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                        {t('contactPages.firstName')}:
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                            name="firstName"
                            className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            placeholder="Enter your first name"
                        />

                        <label htmlFor="lastName" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                        {t('contactPages.lastName')}:
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            placeholder="Enter your last name"
                        />

                        <label htmlFor="email" className="text-xl font-semibold flex justify-left mb-2 ms-1">
                        {t('contactPages.email')}:
                        </label>
                        <input
                            type="email"
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            name="email"
                            className="border w-full mx-1 rounded-md mb-4 p-5 border-[#d5d2c4]"
                            placeholder="Enter your email"
                        />
                    </div>
          <p className="mb-2 border-[#d6d2c4] border mx-4"></p>
          <div className="mt-3 rounded-lg p-4">
            <label
              htmlFor="message"
              className="text-xl font-semibold flex justify-left mb-2 font-normal"
            >
              {t('contactPages.iWouldLikeYouTo')}
            </label>
            <div className="flex items-center mb-3">
              <input
                type="text"
                name=""
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                id="message"
                className="border w-full rounded-md mb-4 p-5 border-[#d5d2c4]"
              />
            </div>
            <p className=" border-[#d6d2c4] border ms-2"></p>
          </div>

          <div className="mt-3 rounded-lg p-4">
            <p className="text-xl text-left mb-5 font-bold">
              {t('contactPages.doYouHavePets')}
            </p>
            <div className="flex items-center mb-6">
              <input
                type="checkbox"
                id="cat"
                name="pets"
                className="mr-4 h-6 w-6 cursor-pointer border-gray-400 rounded-xl"
              />
              <label className="text-lg cursor-pointer" htmlFor="cat">
              {t('contactPages.cat')}
              </label>
            </div>

            <div className="flex items-center mb-6">
              <input
                type="checkbox"
                id="dog"
                name="pets"
                className="mr-4 h-6 w-6 cursor-pointer border-gray-400 rounded-xl"
              />
              <label className="text-lg cursor-pointer" htmlFor="dog">
              {t('contactPages.dog')}
              </label>
            </div>
            <div className="flex items-center mb-6">
              <input
                type="checkbox"
                id="other"
                name="pets"
                className="mr-4 h-6 w-6 cursor-pointer border-gray-400 rounded-xl"
              />
              <label className="text-lg cursor-pointer" htmlFor="other">
              {t('contactPages.otherAnimal')}
              </label>
            </div>
          </div>
          <div className="p-4">
            <h4 className="font-semibold text-left mb-3 text-xl">{t('contactPages.access')}</h4>

            <div className="border rounded-md mb-2 p-5 bg-white border-[#d5d2c4]">
              {/* Radio Button 1 */}
              <div className="flex items-center justify-between mb-2">
                <label
                  htmlFor="open"
                  className="cursor-pointer flex items-center justify-between w-full"
                >
                  <p className="text-xl font-normal">
                    {t('contactPages.iWillOpen')}
                  </p>
                  <div
                    className={`w-7 h-7 rounded-full border border-gray-300 flex items-center justify-center ${handover === "open" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {handover === "open" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  id="open"
                  name="handover"
                  checked={handover === "open"}
                  onChange={() => handleHandoverChange("open")}
                  className="hidden"
                />
              </div>

              <p className="mb-2 border-[#d6d2c4] border"></p>

              {/* Radio Button 2 */}
              <div className="flex my-auro">
                <label
                  className="text-xl cursor-pointer flex items-center jutify-between w-full font-normal"
                  htmlFor="leave"
                >
                  <p className="text-left">
                  {t('contactPages.iWillLeave')}
                  </p>
                  <div
                    className={`w-8 h-7 rounded-full border border-gray-300 flex items-center justify-center ${handover === "leave" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {handover === "leave" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  name="handover"
                  value={"leave"}
                  checked={handover === "leave"}
                  onChange={() => handleHandoverChange("leave")}
                  className="hidden"
                  id="leave"
                />
              </div>

              <p className="mb-2 border-[#d6d2c4] border"></p>

              {/* Radio Button 3 */}
              <div className="flex my-auto">
                <label
                  className="text-xl cursor-pointer flex items-center justify-between w-full font-normal"
                  htmlFor="customer"
                >
                  <p className="text-left">
                  {t('contactPages.iAmACustomer')}
                  </p>
                  <div
                    className={`w-7 h-7 rounded-full border border-gray-300 flex items-center justify-center ${handover === "customer" ? "bg-black" : "bg-white"
                      }`}
                  >
                    {handover === "customer" && (
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    )}
                  </div>
                </label>
                <input
                  type="radio"
                  name="handover"
                  value={"customer"}
                  checked={handover === "customer"}
                  onChange={() => handleHandoverChange("customer")}
                  className="hidden"
                  id="customer"
                />
              </div>
            </div>

            <p className="text-4 text-left italic my-2">
              <a href="https://www.google.com/maps/place/Dalgatan+5B,+1101,+195+47+M%C3%A4rsta,+Sweden/@59.6266695,17.8531464,17z/data=!3m1!4b1!4m5!3m4!1s0x465fbc16cc3bd0ff:0xf9fcc296cf8de420!8m2!3d59.6266668!4d17.8557213?entry=ttu" className="font-semibold underline">
              {t('contactPages.here')}
              </a>{" "}
              {t('contactPages.youWillFind')}
            </p>
            <div
              className="mt-10"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <button
                style={{ padding: "15px 50px 15px 50px" }}
                className="bg-[#000] font-bold uppercase text-white p-5 rounded text-bold"
                onClick={handleSubmit}

              >
                {t('contactPages.moveOn')}
              </button>
            </div>
          </div>
        </div>
        <div className="mt-20 pb-5" style={{ backgroundColor: "#d1dce7", width: "300px", height: "fit-content", }} >
            <div className="flex-col mt-5 px-5">
              <p
                className="text-2xl text-normal text-[#003b5c] text-left"
                style={{ fontFamily: "Tiempos Headline,serif" }}
              >
                {t('contactPages.yourBooking')}
              </p>
            </div>
            <div className="flex mt-5 px-5">
              <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
                Materialkostnad
              </p>
              <p className="text-gl font-bold text-[#003b5c]">25 kr</p>
            </div>
            <div className="flex mt-5 px-5">
              <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
                Storstädning
              </p>
              <p className="text-gl font-bold text-[#003b5c]">
                {storstadningPrice} kr
              </p>
            </div>
            <div className="flex mt-5 px-5">
              <p className="text-lg text-normal text-[#003b5c] text-left flex-1">
                Fönsterputs
              </p>
              <p className="text-gl font-bold text-[#003b5c]">-</p>
            </div>
            <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
              <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
            </div>
            <div className="flex mt-5 px-5">
              <p
                className="text-normal text-normal text-[#003b5c] px-1 bg-[#e8eef3] border text-left"
                style={{ display: "inline-block" }}
              >
                {t('contactPages.discountCode')}
              </p>
              <p className="text-xl font-semibold text-right flex-1 text-[#003b5c]">
                -221 kr
              </p>
            </div>
            <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
              <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
            </div>
            <div className="mt-5 px-5">
              <p className="text-xl font-semibold text-[#003b5c] text-left">
              {t('contactPages.dateAndTime')}
              </p>
              <p className="text-xl text-left font-semibold">-</p>
            </div>
            <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
              <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
            </div>
            <div className="flex mt-5 px-5">
              <div className="flex-1">
                <p className="text-lg text-[#003b5c] text-left flex-1">{t('contactPages.total')}</p>
                <p className="text-sm text-[#003b5c] text-left flex-1 ms-1">
                {t('contactPages.withRUTReduction')}
                </p>
              </div>
              <p className="text-xl font-semibold text-[#003b5c] my-auto">
                {total} kr
              </p>
            </div>
            <div className="w-full mt-3 flex justify-center px-5 items-center mb-5">
              <p className=" border-[#003b5c] border opacity-10 w-full text-center "></p>
            </div>
            <div className="flex mt-5 px-5">
              <p
                className="text-normal text-normal text-[#003b5c] text-left"
                style={{ display: "inline-block" }}
              >
                {t('contactPages.discountCode')}
              </p>
              <p className="text-normal font-normal text-right flex-1 text-[#003b5c] underline">
                FP1123
              </p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default DeepCleaning;
