import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const HelpWith = () => {

    const { t, i18n } = useTranslation();
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
    
    const handleClick = () => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        // Introduce a delay (you can adjust the duration)
        const delayDuration = 500; // in milliseconds
        setTimeout(() => {
            // Now, navigate to the desired route
        }, delayDuration);
    };
    return (
        <div className='bg-[#eae8e1] flex justify-center items-center'>
            <div className="w-5/6 md:w-full relative font-['Roboto'] ">

                <div className="hover:transform scale-90  text-5xl">


                    <div className="pt-36 font-bold">{t('generalComponents.helpWith.headingPart1')} <span className="text-orange-500">{t('generalComponents.helpWith.headingPart2')}</span>{t('generalComponents.helpWith.headingPart3')}</div>
                    <div class="w-full flex justify-center mt-8 relative group ">
                        <p class="border-black hover:border-white border-2 w-20 hover:w-[1000PX] text-center transition-all duration-300 transform origin-center scale-100 "></p>
                    </div>
                </div>



                <div className="flex justify-center items-center flex-col xl:mx-10 lg:mx-7 2xl:mx-80 mt-16 h-auto">
                    <div className="flex flex-col lg:flex-row items-center w-fit" >




                    <div className="w-full lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <Link to="/MovingService" onClick={() => window.scrollTo(0, 0)}>
                                    <img src="img/ha.svg" className="w-8 inline-block hover:shake" alt="" />
                                </Link>
                                <Link to="/MovingService" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0" >{t('generalComponents.helpWith.linkMovingServices')}</p>
                                </Link>
                                <Link to="/MovingService" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="text-center text-4xl absolute end-0 bottom-3 cursor-pointer">
                                        <svg height="24" viewBox="0 0 9 15" width="24" className="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}>
                                            <path d="m.958.995 6.51 6.51-6 6" fill="none" strokeWidth="2"></path>
                                        </svg>
                                    </p>
                                </Link>
                            </div>
                            <p className="border-[#d5d2c4] border-b-2 my-4 md:pl-[450px]"></p>

                        </div>


                        <div>
                            <div className="flex items-start relative">
                                <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="inline-block h-12 hover:shake" src="img/de3.svg" alt="" />
                                </Link>
                                <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('generalComponents.helpWith.linkHomeCleaning')}</p>
                                </Link>
                                <Link to="/Home_clean" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="text-center text-4xl absolute end-0 bottom-3 cursor-pointer">
                                        <svg height="24" viewBox="0 0 9 15" width="24" className="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}>
                                            <path d="m.958.995 6.51 6.51-6 6" fill="none" strokeWidth="2"></path>
                                        </svg>
                                    </p>
                                </Link>
                            </div>
                            <p className="border-[#d5d2c4] border-b-2 my-4 md:pl-[450px]"></p>
                        </div>


                    </div>


                    <div className="flex flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <Link to="/Move_Out_Cleaning" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="h-12 inline-block"
                                        src='img/f.svg'
                                        class="w-10 inline-block hover:shake" alt="" />
                                </Link>
                                <Link to="/Move_Out_Cleaning" onClick={() => window.scrollTo(0, 0)}>

                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('generalComponents.helpWith.linkMovingOutCleaning')}</p>
                                </Link>
                                <Link to="/Move_Out_Cleaning" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="text-center text-4xl   absolute  end-0 top-1 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>
                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pl-[450px] flex-wrap " ></p>
                        </div>
                        <div className="">

                            <div className="flex items-end relative">
                                <Link to="/Deep_Clean" onClick={() => window.scrollTo(0, 0)}>

                                    <img className="h-12 inline-block"
                                        src='img/genral.svg'
                                        class="w-8 inline-block hover:shake" alt="" />

                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('generalComponents.helpWith.linkDeepCleaning')}</p>


                                    <p className="text-center text-4xl   absolute bottom-3 end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>

                                </Link></div>


                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pr-[450px] flex-wrap" ></p>
                        </div>
                    </div>
                    <div className="flex  flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">

                            <div className="flex items-start relative">
                                <Link to="/moving_out_service2" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="h-12 inline-block"
                                        src='img/wi2.svg'
                                        class="h-10 inline-block hover:shake" alt="" />
                                </Link>
                                <Link to="/moving_out_service2" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('generalComponents.helpWith.linkMoveService&MoveoutCleaning')}</p>
                                </Link>
                                <Link to="/moving_out_service2" onClick={() => window.scrollTo(0, 0)}>
                                    <p className="text-center text-4xl   absolute  end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                                <Link />
                            </div>




                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pl-[450px]" ></p>
                        </div>


                        <div>
                            <div className="flex items-start relative flex-wrap">
                                <Link to="/Window_clean" onClick={() => window.scrollTo(0, 0)}>
                                    <img className="h-10 inline-block"
                                        src='img/t.svg'
                                        class="w-10 inline-block hover:shake" alt="" />

                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('generalComponents.helpWith.linkWindowCleaning')}</p>
                                    <p className="text-center text-4xl top-2  absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>

                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pr-[450px]" ></p>
                        </div>

                    </div>


                    <div className="flex flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <Link to="/office_relocation" onClick={() => window.scrollTo(0, 0)}>
                                <img className="h-12 inline-block"
                                    src='img/n.svg'
                                    class="w-8 inline-block hover:shake" alt="" />
                                <p className="w-[180px] inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('generalComponents.helpWith.linkOfficeRelocation')}</p>
                                <p className="text-center text-4xl   absolute end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>


                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pl-[450px]" ></p>
                        </div>
                        <div>
                            <div className="flex items-start relative">
                                <Link to="/Dödsbo" onClick={() => window.scrollTo(0, 0)}>

                                    <img className="h-12 inline-block"
                                        src='img/n3.svg'
                                        class="h-10 inline-block hover:shake" alt="" />

                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">Dödsbo</p>
                                    <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>

                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pr-[450px]" ></p>
                        </div>


                    </div>
                    <div className="flex flex-col lg:flex-row items-center w-fit">

                        <div className="lg:mr-20 mr-0">
                            <div className="flex items-start relative">
                                <Link to="/office_cleaning" onClick={() => window.scrollTo(0, 0)}>
                                <img className="h-12 inline-block"
                                    src='img/wi4.svg'
                                    class="w-10 inline-block hover:shake" alt="" />
                                <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('generalComponents.helpWith.linkOfficeCleaning')}</p>
                                <p className="text-center text-4xl   absolute end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>


                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pl-[450px]" ></p>
                        </div>
                        <div>
                            <div className="flex items-start relative">
                            <Link to="/for_housing_cooperative" onClick={() => window.scrollTo(0, 0)}>
                                        <img className="h-8 inline-block"
                                            src='img/wi4.svg'
                                            class="w-10 inline-block hover:shake" alt="" />
                                <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('generalComponents.helpWith.linkForHousingCooperative')}</p>
                                <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                </Link>
                            </div>

                            <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pr-[450px]" ></p>
                        </div>


                    </div>
                    <div>
                        <div className="flex flex-col lg:flex-row items-center w-fit">

                            <div className="lg:mr-20 mr-0">
                                <div className="flex items-start relative">
                                    <Link to="/home_Renovation" onClick={() => window.scrollTo(0, 0)}>
                                        <img className="h-12 inline-block"
                                            src='img/wi4.svg'
                                            class="w-10 inline-block hover:shake" alt="" />
                                        <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('generalComponents.helpWith.linkHomeRenovation')}</p>
                                        <p className="text-center text-4xl   absolute end-0 top-2 cursor-pointer"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                    </Link></div>


                                <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pl-[450px]" ></p>
                                <div className="flex items-start relative">
                                    <Link to="/Generalhandyman" onClick={() => window.scrollTo(0, 0)}>
                                        <img src="img/ha.svg" className="w-8 inline-block hover:shake" alt="" />
                                    </Link>
                                    <Link to="/Generalhandyman" onClick={() => window.scrollTo(0, 0)}>
                                        <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0" >{t('generalComponents.helpWith.linkGeneralHandyman')}</p>
                                    </Link>
                                    <Link to="/Generalhandyman" onClick={() => window.scrollTo(0, 0)}>
                                        <p className="text-center text-4xl absolute end-0 bottom-3 cursor-pointer">
                                            <svg height="24" viewBox="0 0 9 15" width="24" className="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}>
                                                <path d="m.958.995 6.51 6.51-6 6" fill="none" strokeWidth="2"></path>
                                            </svg>
                                        </p>
                                    </Link>
                                </div>
                                <p className="border-[#d5d2c4] border-b-2 my-4 md:pl-[450px]"></p>

                            </div>

                            <div>
                                <div className="flex items-start relative">
                                    <Link to="/cleaning_as_salery_benifit" onClick={() => window.scrollTo(0, 0)}>
                                        <img className="w-10 inline-block hover:shake"
                                            src='img/wi4.svg'/>
                                        <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('generalComponents.helpWith.linkCleaningasSalaryBenefit')}</p>
                                        <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                    </Link>
                                </div>
                                <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pr-[450px]" ></p>
                                <div className="flex items-start relative">
                                    <Link to="/">
                                        <img className="h-12 inline-block"
                                            src='img/wi4.svg'
                                            class="h-10 inline-block hover:shake" alt="" />
                                    
                                    <p className="w-[180px] md:w-auto inline-block text-xl md:text-2xl ml-11 mr-11 md:mr-0">{t('generalComponents.helpWith.linkAllServices')}</p>
                                    
                                        <p className="text-center text-4xl  top-2 absolute end-0"><svg height="24" viewBox="0 0 9 15" width="24" class="csw9N_C5 fiwAtSA" style={{ stroke: "#1e1e1e" }}><path d="m.958.995 6.51 6.51-6 6" fill="none" stroke-width="2"></path></svg></p>
                                    </Link>
                                </div>
                                <p className="border-[#d5d2c4] border-b-2 w-full my-4 text-center md:pr-[450px]" ></p>
                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </div>

    );


}

export default HelpWith;