import {Link} from "react-router-dom";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';

import { useTranslation } from 'react-i18next';

const FourIconsComponent = ({scrollToSection}) => {
    
    const { t } = useTranslation();

    const handleClick = () => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        // Introduce a delay (you can adjust the duration)
        const delayDuration = 500; // in milliseconds
        setTimeout(() => {
            // Now, navigate to the desired route
        }, delayDuration);
    };

    return (
        <div id="four_icons-component" className="w-full flex flex-row justify-center sm:mt-20 mb-16">
             <style jsx>{`
                .rotate-icon1:hover {
                    transform: rotate(360deg);
                    transition: transform 1s;
                }
                .rotate-icon1:hover .heading1{
                    transform: scale(105%);
                    transition: transform 1s;
                }
                .rotate-icon2:hover {
                    transform: rotate(360deg);
                    transition: transform 1s;
                }
                .rotate-icon2:hover .heading2{
                    transform: scale(105%);
                    transition: transform 1s;
                }
                .rotate-icon3:hover {
                    transform: rotate(360deg);
                    transition: transform 1s;
                }
                .rotate-icon3:hover .heading3{
                    transform: scale(105%);
                    transition: transform 1s;
                }
                .rotate-icon4:hover {
                    transform: rotate(360deg);
                    transition: transform 1s;
                }
                .rotate-icon4:hover .heading4{
                    transform: scale(105%);
                    transition: transform 1s;
                }
                @keyframes vibration {
                    0% { transform: translate(0); }
                    20% { transform: translate(-2px, 2px); }
                    40% { transform: translate(-2px, -2px); }
                    60% { transform: translate(2px, 2px); }
                    80% { transform: translate(2px, -2px); }
                    100% { transform: translate(0); }
                }
                .parent:hover .vibrate{
                    animation: vibration 0.3s linear infinite;
                }
            `}</style>
            <div className="w-4/5 flex flex-col sm:flex-row justify-center items-center sm:items-start p-10 bg-gray-200 rounded-lg relative">
                <div className="flex flex-col justify-center items-center sm:w-1/4 my-4 sm:my-0">
                    <div onClick={() => scrollToSection('free-quote')} className="flex justify-center items-center w-40 h-40 border-4 border-black rounded-full"><ArrowForwardIosRoundedIcon style={{width: '80px', height: '80px'}} 
                    className='rotate-icon1'/></div>
                    <div className="w-4/5 text-bold text-blue-600 sm:my-3 heading1">{t('dodsbo.fourIconsComponent.card1.heading')}</div>
                    <div className="w-4/5">{t('dodsbo.fourIconsComponent.card1.paragraphPart1')} <span className='text-blue-600'><Link to="/Recycling">{t('dodsbo.fourIconsComponent.card1.paragraphPart2')}</Link></span></div>
                </div>
                <div className="flex flex-col justify-center items-center sm:w-1/4 my-4 sm:my-0">
                <div onClick={() => scrollToSection('free-quote')} className="flex justify-center items-center w-40 h-40 border-4 border-black rounded-full"><ArrowForwardIosRoundedIcon style={{width: '80px', height: '80px'}} 
                    className='rotate-icon2'/></div>
                    <div className="w-4/5 text-bold text-blue-600 sm:my-3 heading2">{t('dodsbo.fourIconsComponent.card2.heading')}</div>
                    <div className="">{t('dodsbo.fourIconsComponent.card2.paragraphPart1')}<p><span className='text-blue-600'><Link to="/Recycling"> {t('dodsbo.fourIconsComponent.card2.paragraphPart2')}</Link></span></p></div>
                </div>
                <div className="flex flex-col justify-center items-center sm:w-1/4 my-4 sm:my-0">
                <div onClick={() => window.scrollTo(0, 0)} className="flex justify-center items-center w-40 h-40 border-4 border-black rounded-full"><Link to="/moving_out_service1"><ArrowForwardIosRoundedIcon style={{width: '80px', height: '80px'}} 
                    className='rotate-icon3'/></Link></div>
                    <div onClick={() => window.scrollTo(0, 0)} className="w-4/5 text-bold text-blue-600 sm:my-3 heading3"><Link to="/moving_out_service1">{t('contactPages.movingService')}</Link></div>
                    <div className="">{t('dodsbo.fourIconsComponent.card3.paragraphPart1')}</div>
                </div>
                <div className="flex flex-col justify-center items-center sm:w-1/4 sm:mr-10 my-4 sm:my-0">
                <div onClick={() => window.scrollTo(0, 0)} className="flex justify-center items-center w-40 h-40 border-4 border-black rounded-full"><Link to="/Booking"><CheckRoundedIcon style={{width: '80px', height: '80px'}} 
                    className='rotate-icon4'/></Link></div>
                    <div onClick={() => window.scrollTo(0, 0)} className="w-4/5 text-bold text-blue-600 sm:my-3 heading4"><Link to="/Booking">{t('dodsbo.fourIconsComponent.card4.heading')}</Link></div>
                    <div className="">{t('dodsbo.fourIconsComponent.card4.paragraphPart1')}</div>
                </div>
                <div onClick={() => scrollToSection('call-us')} className='absolute w-full sm:w-auto bottom-0 sm:bottom-[73px] sm:right-[-73px] p-2 bg-green-500 sm:transform sm:rotate-[90deg] text-white cursor-pointer parent' style={{borderTopRightRadius: '5px'}} ><LocalPhoneRoundedIcon className='vibrate'/>{t('dodsbo.fourIconsComponent.callUsAboutDodsbo')}</div>
            </div>
        </div>
    );

}

export default FourIconsComponent;