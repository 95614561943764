import { Link } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const WhyHermified = () => {

    const { t } = useTranslation();

    return (

        <div class="h-fit bg-[#e2c6bb] font-['Roboto'] mb-2">
            <h3 className="text-5xl font-medium pt-28 on ">{t('home.whyFsa.heading.why')} <span className="text-red-500 heading">HMFlytt?</span></h3>

            {/* line content */}
            <div class="w-full flex justify-center mt-8 relative group ">
                <p class="border-black hover:border-white border-2 w-20 hover:w-[250PX] text-center transition-all duration-300 transform origin-center scale-100 "></p>
            </div>
            {/* row begins */}
            <div className="   mt-14 mx-24 h-fit    pb-24  ">

                <div className="flex sm:flex-row flex-col justify-between">
                    {/* first row */}
                    <div className="flex my-3 sm:my-0 flex-col ">


                        <div className=" ml-1 flex  flex-col items-center justify-center  transform hover:scale-105 duration-300">
                            <Link to={"/Moving_Home"} >

                                <div className="bg-[hsla(0,0%,100%,.5)]  self-center  hover:bg-white   h-36 w-36 flex items-center justify-center rounded-full rotations">
                                    <img
                                        src='img/h1.svg'
                                        className="h-12" alt="img_icon" />
                                </div>
                            </Link>
                            <h1 className="font-bold mt-9 text-lg leading-10  hover:delay-1000 hover:duration-500 hover:ease-linear">{t('home.whyFsa.card1.heading')}</h1>
                        </div>
                        <p className="text-base">{t('home.whyFsa.card1.paragraphPart1')} <br /> {t('home.whyFsa.card1.paragraphPart2')}</p>

                    </div>

                    {/* second row */}
                    <div className="flex flex-col my-3 sm:my-0 ">
                        <div className=" ml-1 flex  flex-col items-center justify-center transform hover:scale-105 duration-300">
                            <Link to={"/Window_clean"} >
                                <div className="bg-[hsla(0,0%,100%,.5)]  self-center  hover:bg-white   h-36 w-36 flex items-center justify-center rounded-full rotations">
                                    <img
                                        src='img/h3.svg' className="h-12" alt="img_icon" />
                                </div>
                            </Link>
                            <h1 className="font-bold mt-9 text-lg leading-10 ">{t('home.whyFsa.card2.heading')}</h1>
                        </div>
                        <p className="text-base">{t('home.whyFsa.card2.paragraphPart1')} <br />  {t('home.whyFsa.card2.paragraphPart2')} <br />{t('home.whyFsa.card2.paragraphPart3')} <br /> {t('home.whyFsa.card2.paragraphPart4')}</p>
                    </div>


                    {/* Third row */}

                    <div className="flex  my-3 sm:my-0 flex-col">
                        <div className=" ml-1 flex  flex-col items-center justify-center transform hover:scale-105 duration-300">
                            <Link to={"/Deep_Clean"} >
                                <div className="bg-[hsla(0,0%,100%,.5)]  self-center  hover:bg-white   h-36 w-36 flex items-center justify-center rounded-full rotations">
                                    <img
                                        src='img/de3.svg' className="h-12" alt="img_icon" />
                                </div>
                            </Link>
                            <h1 className="font-bold mt-9 text-lg leading-10 ">{t('home.whyFsa.card3.heading')}</h1>
                        </div>
                        <p className="text-base">{t('home.whyFsa.card3.paragraphPart1')} <br /> {t('home.whyFsa.card3.paragraphPart2')}</p>






                    </div>


                </div>

            </div>
            {/* row ends */}

        </div>
    );



}

export default WhyHermified;